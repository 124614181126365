import type { SportIcon } from "@roman/shared/types/SportIcon";
export const SoccerIcon = ({ className }: SportIcon) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>soccer icon</title>
      <g clipPath="url(#clip0_7178_2437)">
        <path
          d="M12.5938 3.11002V7.61304L16.3302 10.3277L20.011 8.18488V3.98463C19.0214 2.99575 17.8455 2.19222 16.5261 1.6174L12.5938 3.11002Z"
          fill="currentColor"
        />
        <path
          d="M11.9995 8.65038L8.4032 11.2632L9.7769 15.4908H14.2221L15.5958 11.2632L11.9995 8.65038Z"
          fill="currentColor"
        />
        <path
          d="M15.0135 22.9204L17.1063 19.5452L14.3978 16.6793H9.60127L6.89319 19.5448L8.99392 22.9232C10.968 23.4708 13.0359 23.4704 15.0135 22.9204Z"
          fill="currentColor"
        />
        <path
          d="M9.2334 1.0144L11.9995 2.06438L14.767 1.01394C13.8573 0.782717 12.9293 0.666687 12.001 0.666687C11.0728 0.666687 10.1444 0.783022 9.2334 1.0144Z"
          fill="currentColor"
        />
        <path
          d="M20.4779 9.28836L16.7894 11.4356L15.328 15.9332L18.0112 18.7723L22.051 17.2246C22.1855 16.9647 22.3111 16.6988 22.4266 16.4265C22.9423 15.2118 23.2379 13.9411 23.3136 12.6616L20.4779 9.28836Z"
          fill="currentColor"
        />
        <path
          d="M8.67102 15.9332L7.20964 11.4356L3.52181 9.28873L0.686646 12.6714C0.759555 13.8822 1.02917 15.0863 1.49688 16.2438C1.62784 16.5679 1.77305 16.8834 1.93075 17.1907L5.98953 18.7706L8.67102 15.9332Z"
          fill="currentColor"
        />
        <path
          d="M20.9877 18.901L18.2522 19.9525L16.83 22.2461C18.4822 21.4698 19.8983 20.325 20.9877 18.901Z"
          fill="currentColor"
        />
        <path
          d="M21.1996 5.38545V8.29878L23.2612 10.7358C23.1463 9.72581 22.8934 8.72511 22.5023 7.75703C22.1587 6.90657 21.7204 6.11264 21.1996 5.38545Z"
          fill="currentColor"
        />
        <path
          d="M7.16868 22.2457L5.74675 19.9525L3.00964 18.9004C4.09749 20.3224 5.51386 21.4672 7.16868 22.2457Z"
          fill="currentColor"
        />
        <path
          d="M7.66886 10.3277L11.4053 7.61304V3.11002L7.47214 1.6171C6.15437 2.19062 4.9786 2.99437 3.98804 3.98463V8.18488L7.66886 10.3277Z"
          fill="currentColor"
        />
        <path
          d="M0.738037 10.7356L2.79946 8.29878V5.38492C2.31645 6.05939 1.90443 6.79214 1.57242 7.57433C1.13801 8.59781 0.860238 9.6609 0.738037 10.7356Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_7178_2437">
          <rect width="22.6667" height="22.6667" fill="white" transform="translate(0.666748 0.666687)" />
        </clipPath>
      </defs>
    </svg>
  );
};
