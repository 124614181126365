import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import type { TelegramUser } from "@/types/TelegramUser";
import { type AuthHeader, useAuthHeader } from "@roman/shared/hooks/useAuthHeader";
import romanApi, { type RomanApiError } from "@roman/shared/lib/net/romanApi";
import { getErrorMessage } from "@roman/shared/utils/error";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { useTelegram } from "./useTelegram";
import { useUpdateUser } from "./useUpdateUser";

const POLLING_INTERVAL = 10000;
interface ResponseUser {
  user: TelegramUser;
}

interface GetUserRequest {
  telegramUserId: number;
}

const fetchUser = (url: string, telegramUserId: number, headers: AuthHeader) => {
  return romanApi.get<ResponseUser, GetUserRequest>(url, { telegramUserId }, { headers });
};

interface CreateUserRequest {
  telegramUserId: number;
  name: string;
  telegramUserName: string | undefined;
}

export const useUser = () => {
  const navigate = useNavigate();
  const headers = useAuthHeader();
  const [user, setUser] = useState<TelegramUser | null>(null);
  const { telegramUserId, name, photoUrl, telegramUserName } = useTelegram();

  const { data, mutate } = useSWR<ResponseUser>(
    ["/user", telegramUserId],
    ([url, telegramUserId]) => fetchUser(url, Number(telegramUserId), headers),
    { revalidateOnFocus: false }, // Disable auto-revalidation on window focus
  );

  // TOOD: 依存関係を整理
  const { openModal } = useErrorModalContext();
  const handleError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error when updating user", message);
    },
    [openModal],
  );

  const { trigger: triggerUpdateUser } = useUpdateUser(handleError);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!data.user) {
      createUser();
    } else {
      updateUser(data.user);
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (telegramUserId) {
        mutate();
      }
    }, POLLING_INTERVAL); // 5 seconds interval

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [telegramUserId, mutate]);

  const updateUser = async (user: TelegramUser) => {
    setUser(user);

    if (user.photoUrl !== photoUrl || user.telegramUserName !== telegramUserName) {
      await triggerUpdateUser({ userId: user.id, photoUrl: photoUrl || "", telegramUserName: telegramUserName || "" });

      mutate();
    }
  };

  const createUser = async () => {
    if (!telegramUserId || !name) {
      return;
    }

    const data = await romanApi.post<ResponseUser, CreateUserRequest>(
      "/user",
      {
        telegramUserId,
        name,
        telegramUserName,
      },
      { headers },
    );

    setUser(data.user);

    navigate("/tutorial");
  };

  return {
    user,
    setUser,
  };
};
