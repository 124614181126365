import { Center } from "@/components/Center";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { LoadingSpinner } from "@roman/shared/components/LoadingSpinner";
import { useSoccerRound } from "@roman/shared/hooks/soccer/useSoccerRound";
import type { RomanApiError } from "@roman/shared/lib/net/romanApi";
import type { CreateEntry } from "@roman/shared/types/CreateEntry";
import { getErrorMessage } from "@roman/shared/utils/error";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SelectCaptain } from "./SelectCaptain";
import { SelectEntry } from "./SelectEntry";
import { SelectPlayer } from "./SelectPlayer";

export type RoundPageType = "select-player" | "select-captain" | "entry";

export const RoundPage = () => {
  const { roundId } = useParams();
  if (!roundId) throw new Error("roundId is required");

  const [entryDetails, setEntryDetails] = useState<CreateEntry>({
    playerIds: [],
    entries: [],
    detail: {
      type: "0002",
      captainPlayerId: "",
      viceCaptainPlayerId: "",
    },
  });

  const { openModal } = useErrorModalContext();
  const handleError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error getting round", message);
    },
    [openModal],
  );
  const { data, isLoading, mutate } = useSoccerRound(roundId, handleError);
  const [currentPage, setCurrentPage] = useState<RoundPageType>("select-player");

  useEffect(() => {
    if (currentPage === "select-player") {
      mutate();
    }
  }, [currentPage, mutate]);

  if (isLoading || !data)
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );

  if (currentPage === "select-player")
    return (
      <SelectPlayer
        round={data.round}
        setCurrentPage={setCurrentPage}
        setEntryDetails={setEntryDetails}
        entryDetails={entryDetails}
      />
    );
  if (currentPage === "select-captain")
    return (
      <SelectCaptain
        round={data.round}
        setCurrentPage={setCurrentPage}
        setEntryDetails={setEntryDetails}
        entryDetails={entryDetails}
      />
    );
  if (currentPage === "entry")
    return (
      <SelectEntry
        round={data.round}
        setEntryDetails={setEntryDetails}
        entryDetails={entryDetails}
        setCurrentPage={setCurrentPage}
      />
    );

  throw new Error("invalid currentPage");
};
