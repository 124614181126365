import { useLocation, useNavigate } from "react-router-dom";

import { HelpIcon, HistoryIcon, HomeIcon, TrophyIcon } from "@/components/Icons";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useUserContext } from "@/contexts/UserContext";
import { useUserRoundEntryUnits } from "@roman/shared/hooks/soccer/useUserRoundEntryUnits";
import type { RomanApiError } from "@roman/shared/lib/net/romanApi";
import { cn } from "@roman/shared/utils";
import { getErrorMessage } from "@roman/shared/utils/error";
import { useCallback, useMemo } from "react";

export enum NavigationItemId {
  Home = 0,
  Upcoming = 1,
  MyContest = 2,
  Help = 3,
}

const navigationItems = [
  {
    id: NavigationItemId.Home,
    name: "Home",
    href: "/",
    icon: (className: string, gradientColors?: string[]) => (
      <HomeIcon className={className} gradientColors={gradientColors} />
    ),
  },
  {
    id: NavigationItemId.Upcoming,
    name: "Upcoming",
    href: "/my-page?tab=schedule",
    icon: (className: string, gradientColors?: string[]) => (
      <HistoryIcon className={className} gradientColors={gradientColors} />
    ),
  },
  {
    id: NavigationItemId.MyContest,
    name: "My Contest",
    href: "/my-page?tab=live",
    icon: (className: string, gradientColors?: string[]) => (
      <TrophyIcon className={className} gradientColors={gradientColors} />
    ),
  },
  {
    id: NavigationItemId.Help,
    name: "Help",
    href: "/help",
    icon: (className: string, gradientColors?: string[]) => (
      <HelpIcon className={className} gradientColors={gradientColors} />
    ),
  },
];

export const BottomNavigationBar = ({ active }: { active?: NavigationItemId }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentPath = useMemo(() => {
    return `${location.pathname}${location.search}`;
  }, [location]);

  return (
    <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 h-16 bg-black flex items-center justify-around text-icon mb-3 py-6 rounded-lg w-5/6">
      {navigationItems.map((item) => {
        const isActive = active ? item.id === active : item.href === currentPath;

        return (
          <div
            key={item.name}
            className="flex flex-col gap-1 items-center cursor-pointer"
            onClick={() => {
              navigate(item.href);
            }}
          >
            <div className="relative">
              {isActive && item.icon("w-6 h-6", ["#FFE500", "#00E218"])}

              {!isActive && item.icon("w-6 h-6")}

              {item.id === NavigationItemId.MyContest && <MyContestBadge />}
            </div>

            <p className={cn("text-[11px]", isActive && "text-gradient")}>{item.name}</p>
          </div>
        );
      })}
    </div>
  );
};

const MyContestBadge = () => {
  const user = useUserContext();
  const { openModal } = useErrorModalContext();

  const handleError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error fetching user entries", message);
    },
    [openModal],
  );

  const { data, isLoading } = useUserRoundEntryUnits(user.id, handleError);

  const hasLiveRounds = useMemo(
    () => data.userRoundEntryUnits.some((roundEntryUnit) => roundEntryUnit.status === "live"),
    [data],
  );

  if (isLoading) {
    return null;
  }

  if (!hasLiveRounds) {
    return null;
  }

  return (
    <div className="absolute top-0 right-0 w-[8px] h-[8px]">
      <span className="relative flex h-[8px] w-[8px]">
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-gradient-green opacity-75" />
        <span className="border border-background relative inline-flex rounded-full h-[8px] w-[8px] bg-gradient-to-r from-gradient-yellow to-gradient-green" />
      </span>
    </div>
  );
};
