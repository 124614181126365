import { stringify } from "querystring";
import { env } from "@roman/shared/config/env";
import axios, { type RawAxiosRequestHeaders } from "axios";

const createURL = (path: string) => {
  let apiUrl = env.API_URL;
  if (apiUrl.endsWith("/")) {
    apiUrl = apiUrl.slice(0, -1);
  }
  if (path.startsWith("/")) {
    return `${apiUrl}${path}`;
  }
  return `${apiUrl}/${path}`;
};

// FIXME: For ngrok free plan user
///TODO:
const defaultHeaders = { "ngrok-skip-browser-warning": true };

const mergeHeaders = (headers: RawAxiosRequestHeaders = {}) => ({
  ...defaultHeaders,
  ...headers,
});

type ApiOption = {
  headers?: RawAxiosRequestHeaders;
  withCredentials?: boolean;
};

const withDefaultHeaders = ({ headers, ...rest }: ApiOption = {}): ApiOption => ({
  ...rest,
  headers: mergeHeaders(headers),
});

const RomanApi = {
  get: async <T, D = never>(path: string, data?: D, option?: ApiOption) => {
    let url = createURL(path);
    if (data) {
      url += `?${stringify(data)}`;
    }
    const response = await axios.get<T>(url, withDefaultHeaders(option));
    return response.data;
  },
  post: async <T, D>(path: string, data: D, option?: ApiOption) => {
    const url = createURL(path);
    const response = await axios.post<T>(url, data, withDefaultHeaders(option));
    return response.data;
  },
  put: async <T, D>(path: string, data: D, option?: ApiOption) => {
    const url = createURL(path);
    const response = await axios.put<T>(url, data, withDefaultHeaders(option));
    return response.data;
  },
  patch: async <T, D>(path: string, data: D, option?: ApiOption) => {
    const url = createURL(path);
    const response = await axios.patch<T>(url, data, withDefaultHeaders(option));
    return response.data;
  },
  delete: async <T>(path: string, option?: ApiOption) => {
    const url = createURL(path);
    const response = await axios.delete<T>(url, withDefaultHeaders(option));
    return response.data;
  },
};

export type { RomanApiError } from "@roman/shared/utils/error";

export default RomanApi;
