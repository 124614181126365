import { cn } from "@roman/shared/utils";
import type { GradientIconProps } from "./HelpIcon";

export const TrophyIcon = ({ gradientColors = ["#ADADAD", "#ADADAD"], className }: GradientIconProps) => {
  const id = `TrophyIconGradient-${gradientColors[0].substring(1)}-${gradientColors[1].substring(1)}`;

  return (
    <svg viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg" className={cn("w-5 h-5", className)}>
      <defs>
        <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor={gradientColors[0]} />
          <stop offset="100%" stopColor={gradientColors[1]} />
        </linearGradient>
      </defs>
      <path
        d="M4.5 18V16H8.5V12.9C7.68333 12.7167 6.95417 12.3708 6.3125 11.8625C5.67083 11.3542 5.2 10.7167 4.9 9.95C3.65 9.8 2.60417 9.25417 1.7625 8.3125C0.920833 7.37083 0.5 6.26667 0.5 5V4C0.5 3.45 0.695833 2.97917 1.0875 2.5875C1.47917 2.19583 1.95 2 2.5 2H4.5V0H14.5V2H16.5C17.05 2 17.5208 2.19583 17.9125 2.5875C18.3042 2.97917 18.5 3.45 18.5 4V5C18.5 6.26667 18.0792 7.37083 17.2375 8.3125C16.3958 9.25417 15.35 9.8 14.1 9.95C13.8 10.7167 13.3292 11.3542 12.6875 11.8625C12.0458 12.3708 11.3167 12.7167 10.5 12.9V16H14.5V18H4.5ZM4.5 7.8V4H2.5V5C2.5 5.63333 2.68333 6.20417 3.05 6.7125C3.41667 7.22083 3.9 7.58333 4.5 7.8ZM9.5 11C10.3333 11 11.0417 10.7083 11.625 10.125C12.2083 9.54167 12.5 8.83333 12.5 8V2H6.5V8C6.5 8.83333 6.79167 9.54167 7.375 10.125C7.95833 10.7083 8.66667 11 9.5 11ZM14.5 7.8C15.1 7.58333 15.5833 7.22083 15.95 6.7125C16.3167 6.20417 16.5 5.63333 16.5 5V4H14.5V7.8Z"
        fill={`url(#${id})`}
      />
    </svg>
  );
};
