import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

/**
 * Formats a number to a specified number of decimal places.
 * If a threshold is provided, the number will be formatted with no decimal places if it is greater than or equal to the threshold.
 *
 * @param value - The number to format.
 * @param fractionDigits - The number of decimal places to include in the formatted number.
 * @param threshold - An optional threshold above which the number will be formatted with no decimal places.
 * @returns The formatted number as a string.
 */
export const formatNumber = (value: number, fractionDigits = 0, threshold?: number): string => {
  if (threshold === undefined) {
    return value.toFixed(fractionDigits);
  }

  if (value >= threshold) {
    return value.toFixed(0);
  }

  return value.toFixed(fractionDigits);
};

export const formatTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are 0-based
  const day = date.getDate();

  // Get weekday in Japanese
  const weekdays = ["日", "月", "火", "水", "木", "金", "土"];
  const weekday = weekdays[date.getDay()];

  // Get hours and minutes
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // Format AM/PM
  const period = hours >= 12 ? "pm" : "am";
  hours = hours % 12 || 12; // Convert 0 to 12-hour format

  return `${year}-${month}-${day} (${weekday}) ${period} ${hours}:${minutes}`;
};

export const getColorStyleByRank = (rank: number) => {
  switch (rank) {
    case 1:
      return "bg-gradient-to-b from-[#FFE500] to-[#3D4700] bg-clip-text text-transparent font-bold";
    case 2:
      return "bg-gradient-to-b from-[#FFFFFF] to-[#414141] bg-clip-text text-transparent font-bold";
    case 3:
      return "bg-gradient-to-b from-[#CBA080] to-[#693F0D] bg-clip-text text-transparent font-bold";
    default:
      if (rank <= 10) {
        return "text-disabled text-xl font-medium";
      }

      if (rank <= 100) {
        return "text-disabled text-lg font-medium";
      }

      return "text-disabled text-lg font-medium";
  }
};
