import type { League } from "@/pages/Home/constants";
import { useHomePageStore } from "@/store";
import { cn } from "@roman/shared/utils";
import { useCallback, useEffect, useRef, useState } from "react";
import { ChevronDownIcon } from "./Icons/ChevDownIcon";

type SelectLeagueProps = {
  className?: string;
  selectedLeague: League;
  activeLeaguesMap: Record<string, boolean>;
  sortedLeagues: League[];
};

export const SelectLeague: React.FC<SelectLeagueProps> = ({
  className,
  selectedLeague,
  activeLeaguesMap,
  sortedLeagues,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const setSelectedLeague = useHomePageStore((state) => state.setSelectedLeague);

  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className={cn("relative inline-block text-left z-10", className)}>
      <div ref={selectRef} style={{ width: "fit-content" }}>
        <button
          type="button"
          onClick={toggleSelect}
          className={
            "rounded-full font-light bg-gradient-to-r from-gradient-yellow to-gradient-green text-black inline-flex justify-between items-center border border-none shadow-sm px-4 py-2  text-sm focus:outline-none"
          }
        >
          <span>League</span>
          <span className="mx-1">:</span>
          <span>{selectedLeague.name}</span>

          <ChevronDownIcon />
        </button>
      </div>

      {isOpen && (
        <div
          className="overflow-hidden w-full origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-background ring-1 ring-solid ring-opacity-5 focus:outline-none"
          role="menu"
        >
          <div className="overflow-y-scroll max-h-[300px]">
            {sortedLeagues.map((league) => {
              const isActive = activeLeaguesMap[league.seasonId] || false;
              let className = "";
              if (league.seasonId !== "all") {
                className = isActive ? "text-gradient" : "text-text-disabled";
              }

              return (
                <div
                  key={league.seasonId}
                  className="flex items-center space-x-2 px-4 py-3 text-sm text-white bg-background hover:bg-solid focus:outline-none"
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    setSelectedLeague(league);
                    setIsOpen(false);
                  }}
                >
                  {isActive && (
                    <span className="relative flex h-[8px] w-[8px]">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-gradient-green opacity-75" />
                      <span className="border border-background relative inline-flex rounded-full h-[8px] w-[8px] bg-gradient-to-r from-gradient-yellow to-gradient-green" />
                    </span>
                  )}

                  <span className={className}>{league.name}</span>
                  {league.flag && <span>{league.flag}</span>}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
