import { BackButton } from "@/components/Buttons/BackButton";
import { Center } from "@/components/Center";
import { Container } from "@/components/Container";
import { InfoIcon } from "@/components/Icons";
import Tabs from "@/components/Tabs";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useUserContext } from "@/contexts/UserContext";
import { LoadingSpinner } from "@roman/shared/components/LoadingSpinner";
import { useUserRoundEntryUnits } from "@roman/shared/hooks/soccer/useUserRoundEntryUnits";
import type { RomanApiError } from "@roman/shared/lib/net/romanApi";
import { getErrorMessage } from "@roman/shared/utils/error";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BottomNavigationBar, NavigationItemId } from "../Home/BottomNavigationBar";
import { RoundEntryUnits } from "./RoundEntryUnits";

export const MyPage = () => {
  const [activeTab, setActiveTab] = useState<"live" | "schedule" | "ended">("live");

  const user = useUserContext();
  const navigate = useNavigate();

  const { openModal } = useErrorModalContext();
  const handleError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error fetching user entries", message);
    },
    [openModal],
  );
  const { data, isLoading } = useUserRoundEntryUnits(user.id, handleError);
  // Make sure most recent entries are on top
  const reversedUserRoundEntryUnits = [...data.userRoundEntryUnits].reverse();

  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location]);

  const activeTabIndex = useMemo(() => {
    if (activeTab === "live") {
      return 0;
    }

    if (activeTab === "schedule") {
      return 1;
    }

    if (activeTab === "ended") {
      return 2;
    }
  }, [activeTab]);

  const activeBottomNavigationItem = useMemo(() => {
    if (activeTab === "live") {
      return NavigationItemId.MyContest;
    }

    if (activeTab === "schedule") {
      return NavigationItemId.Upcoming;
    }
  }, [activeTab]);

  useEffect(() => {
    if (queryParams.has("tab")) {
      const tab = queryParams.get("tab");

      switch (tab) {
        case "live":
          setActiveTab("live");
          break;
        case "schedule":
          setActiveTab("schedule");
          break;
        case "ended":
          setActiveTab("ended");
          break;
        default:
          setActiveTab("live");
          break;
      }
    }
  }, [queryParams]);

  if (isLoading) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  return (
    <div className="pb-24">
      <Container className="relative flex flex-col items-center justify-center">
        <BackButton className="absolute top-4 left-2" onClick={() => navigate("/")} />
        <div className="relative flex flex-col">
          <img src="/assets/upcoming-mypage-badge.png" alt="badge icon" />
          <div className="absolute top-[15%] right-[25.4%]">
            <img
              className="relative inset-0 m-auto rounded-full"
              style={{ width: "4.4rem", height: "4.4rem" }}
              src={user.photoUrl || "/assets/default-profile.svg"}
              draggable={false}
              alt="profile"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center w-full max-w-64 gap-2 text-center mb-8">
          <div className="text-icon text-xs">-- / --</div>
          <div className="bg-[#264C00] opacity-90 h-1 flex items-center">
            <div
              className="bg-gradient-to-r from-[#00E218] to-[#FFE500] h-1"
              style={{ width: `${(8000 / 10000) * 100}%` }}
            />
          </div>
          <div className="flex justify-center items-center gap-1">
            <p className="text-xs text-icon">Total Rank Points</p>
            <img src="/assets/Points-coins.svg" alt="points coin icon" />
            <p className="text-white">--</p>
            <InfoIcon onClick={() => {}} />
          </div>
        </div>
      </Container>
      <Tabs defaultIndex={activeTabIndex}>
        <Tabs.Item title="Live" onClick={() => navigate("/my-page?tab=live")}>
          <RoundEntryUnits entryUnits={reversedUserRoundEntryUnits.filter((unit) => unit.status === "live")} />
          <BottomNavigationBar />
        </Tabs.Item>

        <Tabs.Item title="Schedule" onClick={() => navigate("/my-page?tab=schedule")}>
          <RoundEntryUnits entryUnits={reversedUserRoundEntryUnits.filter((unit) => unit.status === "schedule")} />
        </Tabs.Item>

        <Tabs.Item title="Ended" onClick={() => navigate("/my-page?tab=ended")}>
          <RoundEntryUnits
            entryUnits={reversedUserRoundEntryUnits.filter(
              (unit) => unit.status === "prizeDistributed" || unit.status === "cancelled",
            )}
          />
        </Tabs.Item>
      </Tabs>

      <BottomNavigationBar active={activeBottomNavigationItem} />
    </div>
  );
};
