import { BackButton } from "@/components/Buttons/BackButton";
import { PrimaryButton } from "@/components/Buttons/PrimaryButton";
import { Container } from "@/components/Container";
import { Deadline } from "@/components/Deadline";
import type { RoundPageType } from "@/pages/Round";
import { PlayerCard } from "@/pages/Round/PlayerCard";
import { useCountDown } from "@roman/shared/hooks/useCountDown";

import type { CreateEntry } from "@roman/shared/types/CreateEntry";
import type { Player } from "@roman/shared/types/Player";
import type { RoundDetails } from "@roman/shared/types/RoundDetails";
import { cn } from "@roman/shared/utils";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { RoundInfoBanner } from "./RoundInfo/RoundInfoBanner";

interface Props {
  round: RoundDetails;
  setCurrentPage: (page: RoundPageType) => void;
  setEntryDetails: (body: CreateEntry) => void;
  entryDetails: CreateEntry;
}

export const SelectPlayer: React.FC<Props> = ({ round, entryDetails, setCurrentPage, setEntryDetails }) => {
  const [isRoundInfoTabsOpen, setIsRoundInfoTabsOpen] = useState(false);
  // TODO: Remove this and create a different page for player fps
  // https://app.asana.com/0/1207835129689105/1208679872629129/f
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState(0);

  const maxPlayerSelections = round.type === "Round0001" ? 11 : 5;

  const { deadlineTime } = useCountDown(new Date(round.entryCloseTime));

  const getPlayerById = (id: string) => {
    return (
      round.players.homePlayers.find((player) => player.id === id) ||
      round.players.awayPlayers.find((player) => player.id === id)
    );
  };

  const handleSelectPlayer = (chosenPlayer: Player) => {
    const selectedPlayers = [...entryDetails.playerIds];
    selectedPlayers[currentSelectedIndex] = chosenPlayer.id;

    // edit players, incomplete scenario
    if (currentSelectedIndex < selectedPlayers.length && selectedPlayers.length < maxPlayerSelections) {
      setCurrentSelectedIndex(selectedPlayers.length);
    } else if (currentSelectedIndex === selectedPlayers.length - 1 && currentSelectedIndex < maxPlayerSelections - 1) {
      // normal flow
      setCurrentSelectedIndex((prevIndex) => prevIndex + 1);
    }

    setEntryDetails({
      ...entryDetails,
      playerIds: selectedPlayers,
    });
  };

  const currentPlayers = {
    playerLeft: round.players.homePlayers[currentSelectedIndex],
    playerRight: round.players.awayPlayers[currentSelectedIndex],
  };

  const handlePlayerSlotClick = (index: number) => {
    setCurrentSelectedIndex(index);
  };

  const hasAnySelection = entryDetails.playerIds[currentSelectedIndex] !== undefined;

  return (
    <Container className="flex flex-col h-screen">
      <div className="flex mb-10">
        <BackButton />
        <RoundInfoBanner
          round={round}
          isRoundInfoTabsOpen={isRoundInfoTabsOpen}
          onCloseRoundInfoTabs={() => setIsRoundInfoTabsOpen(false)}
          onOpenRoundInfoTabs={() => setIsRoundInfoTabsOpen(true)}
        />
      </div>
      <Deadline deadlineTime={deadlineTime} />
      <div className="fle flex-col px-10 h-full">
        <p className="text-center text-white mb-4 text-sm">Select Your Team</p>
        <div className="flex gap-[20px] justify-center">
          <PlayerCard
            player={currentPlayers.playerLeft}
            roundId={round.id}
            onClick={() => handleSelectPlayer(currentPlayers.playerLeft)}
            isSelected={entryDetails.playerIds.includes(currentPlayers.playerLeft.id)}
            hasAnySelection={hasAnySelection}
          />
          <PlayerCard
            player={currentPlayers.playerRight}
            roundId={round.id}
            onClick={() => handleSelectPlayer(currentPlayers.playerRight)}
            isSelected={entryDetails.playerIds.includes(currentPlayers.playerRight.id)}
            hasAnySelection={hasAnySelection}
          />
        </div>
      </div>
      <div className="flex flex-wrap gap-1 justify-center items-center -mx-3 h-full">
        {Array.from({ length: maxPlayerSelections }, (_, index) => {
          const player = getPlayerById(entryDetails.playerIds[index]);

          if (!player) return <div className="rounded-md w-7 h-10 bg-button-background" key={uuidv4()} />;

          return (
            <div
              onClick={() => handlePlayerSlotClick(index)}
              className={cn(
                "rounded-md w-7 h-10 bg-button-background hover:cursor-pointer overflow-hidden",
                currentSelectedIndex === index && "border border-gradient-yellow",
              )}
              key={player.id}
            >
              <img src={player.imageUrl || "/temp/player1.png"} alt={player.name} />
            </div>
          );
        })}
      </div>
      <div className="flex justify-end items-end">
        <PrimaryButton
          text="Enter"
          onClick={() => setCurrentPage("select-captain")}
          disabled={entryDetails.playerIds.length !== maxPlayerSelections}
        />
      </div>
    </Container>
  );
};
