import { InfoIcon } from "@/components/Icons";
import { Skeleton } from "@/components/Skeleton";
import { useTonUsdPriceContext } from "@/contexts/TonUsdContext";
import { LoadingSpinner } from "@roman/shared/components/LoadingSpinner";
import { useCountDown } from "@roman/shared/hooks/useCountDown";
import type { Round } from "@roman/shared/types/Round";
import { formatNumber } from "@roman/shared/utils";
import { fromNano } from "@ton/ton";
import { useMemo, useState } from "react";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";
import { RoundInfo } from "../Round/RoundInfo";
import { LEAGUES } from "./constants";

interface Props {
  round: Round;
}

export const SoccerRoundsCard: FC<Props> = ({ round }) => {
  const navigate = useNavigate();
  const [isRoundInfoTabsOpen, setIsRoundInfoTabsOpen] = useState(false);
  const { deadlineTime } = useCountDown(new Date(round.entryCloseTime));
  const { tonUsdPrice } = useTonUsdPriceContext();

  const totalUsdPrize = useMemo(() => {
    if (tonUsdPrice !== undefined) {
      const prizeInTon = Number(fromNano(round.prizePool));
      const prizeInUsd = prizeInTon * tonUsdPrice;
      return `$${formatNumber(prizeInUsd, 2, 100)}`;
    }
  }, [tonUsdPrice, round.prizePool]);

  const season = useMemo(() => {
    return LEAGUES.find((league) => league.seasonId === round.seasonId);
  }, [round.seasonId]);

  const {
    id,
    imageUrl,
    description,
    homeName,
    awayName,
    entryFee,
    numEntries,
    maxNumEntries,
    abbreviatedName,
    sportEventName,
    sportEventStartTime,
  } = round;

  return (
    <>
      <div
        className="w-full relative aspect-square bg-cover bg-center bg-chicago-700 rounded-[20px] text-white mb-4 cursor-pointer z-0 h-[144px]"
        style={{ backgroundImage: `url(${imageUrl})` }}
        onClick={() => navigate(`/rounds/${id}`)}
        onKeyDown={(e) => e.key === "Enter" && navigate(`/rounds/${id}`)}
      >
        <InfoIcon
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            setIsRoundInfoTabsOpen(true);
          }}
          className="absolute w-5 top-2.5 right-2.5 z-10"
        />

        <div className="p-[5px] flex flex-col items-center justify-between h-full w-full">
          <div className="text-white font-semibold text-xs flex gap-1">
            <p>{season?.name}</p>
            <p>{description}</p>
          </div>

          <div className="text-xs flex w-full items-center justify-center space-x-2 font-bold">
            <p className="w-1/3 text-center">{homeName}</p>
            <p className="font-normal">vs</p>
            <p className="w-1/3 text-center">{awayName}</p>
          </div>

          <div className="flex z-[999] space-x-2 w-full justify-between items-center pl-[12px]">
            <div className="w-1/4 flex items-start h-full">
              <div className="flex flex-col items-center justify-between gap-1 h-full pb-1">
                <p className="text-[8px]">Entry fee</p>
                <div>
                  {Number.parseInt(entryFee) > 0 ? (
                    <div className="flex gap-1 items-center">
                      <img className="h-[20px]" src="/assets/new-ton.png" alt="Ton icon" />
                      <p className="text-[20px] font-semibold">{fromNano(entryFee)}</p>
                    </div>
                  ) : (
                    <span className="text-[20px]">Free</span>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center justify-between gap-1 h-full">
              <p className="text-[8px]">Total Prizes</p>
              <div className="text-[28px] text-gradient">
                {totalUsdPrize ? (
                  <span className="text-[28px] font-semibold">{totalUsdPrize}</span>
                ) : (
                  <LoadingSpinner />
                )}
              </div>
            </div>

            <div className="flex flex-col items-end gap-1 text-[8px] w-5/12">
              <div className="flex w-full items-center space-x-[5px]">
                <p className="text-right w-5/12">Entries</p>
                <p className="text-[11px] font-semibold text-right flex-1">
                  {numEntries.toLocaleString()}/{maxNumEntries.toLocaleString()}
                </p>
              </div>
              <div className="flex w-full items-center space-x-[5px]">
                <span className="text-right w-5/12">Deadline time</span>
                {deadlineTime ? (
                  <p className="text-[11px] font-semibold text-right flex-1">{deadlineTime}</p>
                ) : (
                  <Skeleton className="w-[11px]" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 w-full h-[44%] bg-gradient-to-b from-transparent to-chicago-700 rounded-b-[20px] z-10" />
      </div>
      {isRoundInfoTabsOpen && (
        <RoundInfo
          sportName={abbreviatedName ?? sportEventName}
          roundId={id}
          sportEventStartTime={sportEventStartTime}
          onClose={() => setIsRoundInfoTabsOpen(false)}
        />
      )}
    </>
  );
};
